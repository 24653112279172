<template>
  <div>
    <filters />

    <a-input
      :manual-doc="userDoc"
      field="routes.failureParts.search"
      :label="$t('common.quick_search')"
    />
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { values, map, uniqBy, toPairs, concat, sortBy, keys } from 'lodash'
import Filters from './Filters'

export default {
  components: {
    Filters
  },
  computed: {
    userDoc () {
      return { module: 'user' }
    }
  },
  methods: {
    keys
  }
}
</script>
